body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.topbar{background: #D4794B;height:55px;}
.topbar .navbar-header{width:100%}
.topbar img {width: 170px;}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {color:white;}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {font-weight: 600;}

.case-icons img{height: 25px;margin-right: 10px;}

.list-item li .nav-link{color: white;}
.list-item li:hover{background: white;}
.list-item li:hover .nav-link{color: black;}

.case-top-menu{padding-top: 0;padding-bottom: 0;}
.case-top-menu.navbar-light .navbar-nav .nav-item{border-right: 2px solid white;padding: 0 10px;}
.case-top-menu.navbar-light .navbar-nav .nav-item.active {background-color: #278888;}
.case-top-menu.navbar-light .navbar-nav .nav-item:hover {background-color: #ccd2d8;}
.case-top-menu.navbar-light .navbar-nav .nav-item.active .nav-link {color:white}
.case-top-menu.navbar-light .navbar-nav .nav-item:hover .nav-link{color:black}

.case-left-menu.navbar-light {padding: 20px;}
.case-left-menu.navbar-light.navbar-expand {padding: 0;}
.case-left-menu.navbar-light .navbar-nav .nav-item{border-bottom: 2px solid white;padding: 0 10px;}
.case-left-menu.navbar-light.navbar-expand .navbar-nav .nav-item{border-bottom: none;border-right: 2px solid white;padding: 0;}
.case-left-menu.navbar-light .navbar-nav .nav-item.active {background-color: #278888;}
.case-left-menu.navbar-light .navbar-nav .nav-item:hover {background-color: #ccd2d8;}
.case-left-menu.navbar-light .navbar-nav .nav-item.active .nav-link {color:white}
.case-left-menu.navbar-light .navbar-nav .nav-item:hover .nav-link{color:black}

.bg-gray{background-color: #efefef;}
